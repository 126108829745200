import { Close, Menu } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppRoutes, routes } from "../../../config/routes";
import { useEffect, useState } from "react";
import { LanguageSelector } from "../language-selector";

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.documentElement.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    } else document.body.style.overflow = "visible";
  }, [isMenuOpen]);

  const Route = ({
    route,
    type = "navbar",
  }: {
    route: AppRoutes;
    type?: "navbar" | "mobile-navbar";
  }) => {
    const isCurrentRoute = window.location.pathname === routes[route];

    return (
      <Typography
        onClick={() => {
          if (isMenuOpen) setIsMenuOpen(false);
          navigate(routes[route]);
        }}
        sx={{
          "&:hover": {
            cursor: "pointer",
            color: "primary.main",
            userSelect: "none",
          },
          color: isCurrentRoute ? "primary.main" : "text.primary",
          borderBottom:
            isCurrentRoute && type === "navbar" ? "1px solid" : "none",
        }}
        variant={isMobile ? "h4" : "body1"}
      >
        {route[0].toUpperCase() + route.slice(1)}
      </Typography>
    );
  };

  return (
    <Stack
      direction={"row"}
      padding={isMobile ? "24px 20px" : "24px 108px"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        ...(isMobile &&
          !isMenuOpen && { boxShadow: "0px 3px 6px 0px #100f1917" }),
      }}
      zIndex={"1000"}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        maxWidth={isMobile ? "600px" : "100%"}
        width={"100%"}
      >
        <Typography
          variant="h5"
          onClick={() => {
            if (isMenuOpen) setIsMenuOpen(false);
            navigate(routes.home);
          }}
          sx={{
            "&:hover": {
              cursor: "pointer",
              userSelect: "none",
            },
          }}
        >
          Zzp Jobs
        </Typography>

        {isMobile ? (
          <Stack direction={"row"} alignItems={"center"}>
            <LanguageSelector />
            <IconButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
              {isMenuOpen ? <Close /> : <Menu />}
            </IconButton>
          </Stack>
        ) : (
          <Stack direction={"row"} alignItems={"center"} spacing={"60px"}>
            <LanguageSelector />

            <Route route="home" />
            <Route route="jobs" />
            <Route route="contact" />
          </Stack>
        )}
      </Box>
      {isMenuOpen && isMobile && (
        <Box
          height={"100%"}
          width={"100%"}
          maxWidth={isMobile ? "600px" : "100%"}
          minHeight={"100vh"}
          zIndex={"999"}
          position={"fixed"}
          top={"88px"}
          padding="24px 20px"
          sx={{
            background: "#FFF",
          }}
        >
          <Stack spacing={"30px"} mt={8}>
            <Route route="home" type="mobile-navbar" />
            <Route route="jobs" type="mobile-navbar" />
            <Route route="contact" type="mobile-navbar" />
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

export { Navbar };
