import { Place, WorkHistory } from "@mui/icons-material";
import { Box, Button, Chip, Grid, Stack, Typography } from "@mui/material";
import { JobOffer } from "../../../utils/types";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../config/routes";

interface Props {
  data: JobOffer;
}

export const JobOfferCard = ({ data }: Props) => {
  const {
    company,
    salary,
    experience,
    shortDescription,
    role,
    location,
    tags,
    id,
  } = data;
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        maxWidth: "600px",
        width: "100%",
        boxShadow: "0px 3px 6px 6px #100f1917",
        padding: "25px 20px",
        display: "flex",
        borderRadius: "20px",
        alignItems: "center",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box width={"100%"} height={"100%"}>
            <Grid container>
              <Grid item xs={8}>
                <Box width={"100%"} height={"100%"} padding={"0 16px"}>
                  <Typography variant="h5" fontWeight={"bold"}>
                    {company}
                  </Typography>
                  <Typography>{role}</Typography>
                  <Typography variant="body1" mt={2}>
                    {salary}
                  </Typography>
                  <Stack direction={"row"} mt={2} spacing={"8px"}>
                    <WorkHistory />
                    <Typography>{experience}</Typography>
                  </Stack>
                  <Typography mt={2} maxWidth={"300px"}>
                    {shortDescription}
                  </Typography>
                  <Stack direction={"row"} mt={2} flexWrap={"wrap"} gap={"2px"}>
                    {tags.map((tag) => (
                      <Chip
                        key={tag + id}
                        label={tag}
                        sx={{
                          background: "darkgray",
                          color: "#FFF",
                        }}
                      />
                    ))}
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  width={"100%"}
                  height={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Stack>
                    <Stack
                      direction={"row"}
                      spacing={"8px"}
                      alignItems={"center"}
                    >
                      <Place />
                      <Typography variant="h6" fontWeight={"bold"}>
                        {location}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Button
                    variant="contained"
                    size="medium"
                    sx={{
                      borderRadius: "20px",
                      textTransform: "none",
                    }}
                    onClick={() => navigate(routes.details.replace(":id", id))}
                  >
                    Learn more
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
