import { Box, useMediaQuery, useTheme } from "@mui/material";
import { ReactNode, useLayoutEffect } from "react";
import { Layout } from "../layout";
import { useLocation } from "react-router-dom";
import { Navbar } from "../navbar";
import { Footer } from "../footer";

interface Props {
  children: ReactNode;
}

const PageWrapper = ({ children }: Props) => {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Box>
      <Box
        sx={{
          ...(!isMobile && { boxShadow: "0px 3px 6px 0px #100f1917" }),
          width: "100%",
        }}
      >
        <Box maxWidth={"1440px"} margin={"0 auto"}>
          <Navbar />
        </Box>
      </Box>
      <Box maxWidth={"1440px"} margin={"0 auto"}>
        {children}
      </Box>
      <Box
        sx={{
          backgroundColor: "#FAFAFB",
          width: "100%",
        }}
      >
        <Box maxWidth={"1440px"} margin={"0 auto"}>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export { PageWrapper };
