import { createBrowserRouter } from "react-router-dom";
import HomeView from "./ui/views/home";
import { routes } from "./config/routes";
import JobsView from "./ui/views/jobs";
import ContactView from "./ui/views/contact";
import JobApplyView from "./ui/views/job-apply";
import JobDetailsView from "./ui/views/job-details";
import NotFoundView from "./ui/views/not-found";

const router = createBrowserRouter([
  {
    path: routes.home,
    element: <HomeView />,
  },
  {
    path: routes.jobs,
    element: <JobsView />,
  },
  {
    path: routes.contact,
    element: <ContactView />,
  },
  {
    path: routes.apply,
    element: <JobApplyView />,
  },
  {
    path: routes.details,
    element: <JobDetailsView />,
  },
  {
    path: "*",
    element: <NotFoundView />,
  },
]);

export { router };
