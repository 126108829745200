import {
  Box,
  Button,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PageWrapper } from "../../components/page-wrapper";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { routes } from "../../../config/routes";
import { JOB_OFFERS } from "../../../config/offers";
import { ArrowBack } from "@mui/icons-material";

const JobDetailsView = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      navigate(routes.jobs);
    }
  }, [id]);

  const offerId = id as string;

  const offerData = JOB_OFFERS.find((offer) => offer.id === id);

  return (
    <PageWrapper>
      <Box
        padding={isMobile ? "80px 20px" : "80px 108px"}
        height={"100%"}
        minHeight={"100vh"}
      >
        <Box
          width={"100px"}
          onClick={() => navigate(routes.jobs)}
          display={"flex"}
          alignItems={"center"}
          sx={{
            cursor: "pointer",
            "&:hover": {
              opacity: 0.8,
            },
          }}
        >
          <ArrowBack />
          <Typography variant="h6">Go back</Typography>
        </Box>

        <Typography variant="h3" mt={4}>
          {offerData?.role}
        </Typography>
        <Typography variant="h5" mt={1}>
          {offerData?.company}
        </Typography>
        <Typography variant="body1" mt={1}>
          {offerData?.location}
        </Typography>

        <Stack direction={"row"} mt={4} flexWrap={"wrap"} gap={"2px"}>
          {offerData?.tags.map((tag) => (
            <Chip
              key={tag + id}
              label={tag}
              sx={{
                background: "darkgray",
                color: "#FFF",
              }}
            />
          ))}
        </Stack>

        <Box mt={8}>
          <Typography variant="h4">Description</Typography>
          <Typography
            variant="body1"
            mt={2}
            maxWidth={"500px"}
            textAlign={"justify"}
          >
            {offerData?.longDescription}
          </Typography>
        </Box>
        <Box mt={8}>
          <Typography variant="h4">Requirements</Typography>
          <Typography variant="body1" mt={2}>
            {offerData?.experience} of experience
          </Typography>
        </Box>
        <Box mt={8}>
          <Typography variant="h4">Salary & benefits</Typography>
          <Typography variant="body1" mt={2}>
            {offerData?.salary}
          </Typography>
        </Box>
        <Box mt={4}>
          <Button
            onClick={() => navigate(routes.apply.replace(":id", offerId))}
            variant="contained"
            size="large"
            sx={{
              borderRadius: "20px",
            }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default JobDetailsView;
