import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../../config/routes";
import { useTranslation } from "react-i18next";

const HeroSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      width={"100%"}
      padding={isMobile ? "40px 20px" : "140px 108px"}
      height={"100%"}
      minHeight={"100vh"}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          lg={6}
          order={{
            lg: 1,
            xs: 2,
          }}
        >
          <Stack
            width={"100%"}
            height={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
            mt={isMobile ? 4 : 0}
          >
            <Box maxWidth={"600px"}>
              <Typography variant="h3" fontWeight={"bold"}>
                Searching for a new job?
              </Typography>
              <Typography variant="h4" fontWeight={"bold"} mt={2}>
                {t("homepage.hero.subtitle")}
              </Typography>
              <Typography mt={2} mb={4}>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Possimus iste provident nam vitae nobis quis deserunt animi quos
                dicta, dolores odio quas autem? Vitae est, dolore asperiores
                corporis explicabo quas.
              </Typography>

              <Button
                variant="outlined"
                size="large"
                sx={{
                  borderRadius: "20px",
                  mr: 2,
                  textTransform: "none",
                }}
                onClick={() => navigate(routes.jobs)}
              >
                Explore Jobs
              </Button>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  borderRadius: "20px",
                  textTransform: "none",
                }}
                onClick={() => navigate(routes.contact)}
              >
                Contact us
              </Button>
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          order={{
            lg: 2,
            xs: 1,
          }}
        >
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img
              src="/hero.jpg"
              alt="hero-image"
              style={{
                height: "100%",
                maxWidth: "600px",
                width: "100%",
                borderRadius: "40px",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export { HeroSection };
