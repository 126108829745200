import { JobOffer } from "../utils/types";

export const JOB_OFFERS: JobOffer[] = [
  {
    id: "1",
    role: "Frontend Developer",
    company: "Google",
    location: "London",
    salary: "40000-45000$/year",
    experience: "2-3 years",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",

    longDescription:
      "Lorem ipsum dolor sit amet, sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit consectetur adipiscing elit. D",
    tags: ["React", "Redux", "Javascript", "HTML", "CSS"],
  },
  {
    id: "2",
    role: "Backend Developer",
    company: "Facebook",
    location: "London",
    salary: "40000-45000$/year",
    experience: "2-3 years",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    longDescription:
      "Lorem ipsum dolor sit amet, sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit consectetur adipiscing elit. D",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    tags: ["React", "Redux", "Javascript", "HTML", "CSS"],
  },
  {
    id: "3",
    role: "Frontend Developer",
    company: "Google",
    location: "London",
    salary: "40000-45000$/year",
    experience: "2-3 years",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",

    longDescription:
      "Lorem ipsum dolor sit amet, sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit consectetur adipiscing elit. D",
    tags: ["React", "Redux", "Javascript", "HTML", "CSS"],
  },
  {
    id: "4",
    role: "Backend Developer",
    company: "Facebook",
    location: "London",
    salary: "40000-45000$/year",
    experience: "2-3 years",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    longDescription:
      "Lorem ipsum dolor sit amet, sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit consectetur adipiscing elit. D",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    tags: ["React", "Redux", "Javascript", "HTML", "CSS"],
  },
  {
    id: "5",
    role: "Frontend Developer",
    company: "Google",
    location: "London",
    salary: "40000-45000$/year",
    experience: "2-3 years",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",

    longDescription:
      "Lorem ipsum dolor sit amet, sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit consectetur adipiscing elit. D",
    tags: ["React", "Redux", "Javascript", "HTML", "CSS"],
  },
  {
    id: "6",
    role: "Backend Developer",
    company: "Facebook",
    location: "London",
    salary: "40000-45000$/year",
    experience: "2-3 years",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    longDescription:
      "Lorem ipsum dolor sit amet, sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit consectetur adipiscing elit. D",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    tags: ["React", "Redux", "Javascript", "HTML", "CSS"],
  },
  {
    id: "7",
    role: "Frontend Developer",
    company: "Google",
    location: "London",
    salary: "40000-45000$/year",
    experience: "2-3 years",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",

    longDescription:
      "Lorem ipsum dolor sit amet, sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit consectetur adipiscing elit. D",
    tags: ["React", "Redux", "Javascript", "HTML", "CSS"],
  },
  {
    id: "8",
    role: "Backend Developer",
    company: "Facebook",
    location: "London",
    salary: "40000-45000$/year",
    experience: "2-3 years",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    longDescription:
      "Lorem ipsum dolor sit amet, sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit consectetur adipiscing elit. D",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    tags: ["React", "Redux", "Javascript", "HTML", "CSS"],
  },
  {
    id: "9",
    role: "Frontend Developer",
    company: "Google",
    location: "London",
    salary: "40000-45000$/year",
    experience: "2-3 years",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",

    longDescription:
      "Lorem ipsum dolor sit amet, sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit consectetur adipiscing elit. D",
    tags: ["React", "Redux", "Javascript", "HTML", "CSS"],
  },
  {
    id: "10",
    role: "Backend Developer",
    company: "Facebook",
    location: "London",
    salary: "40000-45000$/year",
    experience: "2-3 years",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    longDescription:
      "Lorem ipsum dolor sit amet, sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit consectetur adipiscing elit. D",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    tags: ["React", "Redux", "Javascript", "HTML", "CSS"],
  },
  {
    id: "11",
    role: "Frontend Developer",
    company: "Google",
    location: "London",
    salary: "40000-45000$/year",
    experience: "2-3 years",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",

    longDescription:
      "Lorem ipsum dolor sit amet, sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit consectetur adipiscing elit. D",
    tags: ["React", "Redux", "Javascript", "HTML", "CSS"],
  },
  {
    id: "12",
    role: "Backend Developer",
    company: "Facebook",
    location: "London",
    salary: "40000-45000$/year",
    experience: "2-3 years",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    longDescription:
      "Lorem ipsum dolor sit amet, sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit consectetur adipiscing elit. D",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    tags: ["React", "Redux", "Javascript", "HTML", "CSS"],
  },
  {
    id: "13",
    role: "Frontend Developer",
    company: "Google",
    location: "London",
    salary: "40000-45000$/year",
    experience: "2-3 years",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",

    longDescription:
      "Lorem ipsum dolor sit amet, sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit consectetur adipiscing elit. D",
    tags: ["React", "Redux", "Javascript", "HTML", "CSS"],
  },
  {
    id: "14",
    role: "Backend Developer",
    company: "Facebook",
    location: "London",
    salary: "40000-45000$/year",
    experience: "2-3 years",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    longDescription:
      "Lorem ipsum dolor sit amet, sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit consectetur adipiscing elit. D",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    tags: ["React", "Redux", "Javascript", "HTML", "CSS"],
  },
  {
    id: "15",
    role: "Frontend Developer",
    company: "Google",
    location: "London",
    salary: "40000-45000$/year",
    experience: "2-3 years",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",

    longDescription:
      "Lorem ipsum dolor sit amet, sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit consectetur adipiscing elit. D",
    tags: ["React", "Redux", "Javascript", "HTML", "CSS"],
  },
  {
    id: "16",
    role: "Backend Developer",
    company: "Facebook",
    location: "London",
    salary: "40000-45000$/year",
    experience: "2-3 years",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    longDescription:
      "Lorem ipsum dolor sit amet, sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit sit amet, consectetur adipiscing elit consectetur adipiscing elit. D",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. D",
    tags: ["React", "Redux", "Javascript", "HTML", "CSS"],
  },
];
