import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      padding={isMobile ? "50px 20px" : "100px 108px"}
      width={"100%"}
      mt={8}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box maxWidth={isMobile ? "600px" : "100%"} width={"100%"}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5">ZZP Jobs</Typography>
            <Stack mt={2}>
              <Typography>Recruitment</Typography>
            </Stack>
          </Grid>
          <Grid item xs={6} lg={3} mt={isMobile ? 2 : 0}>
            <Typography variant="h5">Services</Typography>
            <Stack mt={2}>
              <Typography>Job List</Typography>
              <Typography>Contact Us</Typography>
            </Stack>
          </Grid>
          <Grid item xs={6} lg={3} mt={isMobile ? 2 : 0}>
            <Typography variant="h5">Contact</Typography>
            <Stack mt={2}>
              <Typography>Phone +31 357 142 567</Typography>
              <Typography>Jumper Straat</Typography>
              <Typography>Spijkenisee</Typography>
              <Typography>33-530</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export { Footer };
