import {
  Box,
  Button,
  Grid,
  Pagination,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PageWrapper } from "../../components/page-wrapper";
import { JobOfferCard } from "../../components/job-offer-card";
import { useState } from "react";
import { JOB_OFFERS } from "../../../config/offers";
import { JobOffer } from "../../../utils/types";

const JOBS = JOB_OFFERS;
const JOBS_PER_PAGE = 4;

const JobsView = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredJobs, setFilteredJobs] = useState<JobOffer[]>([]);
  const [filttering, setFiltering] = useState(false);

  const startIndex = (currentPage - 1) * JOBS_PER_PAGE;
  const endIndex = startIndex + JOBS_PER_PAGE;

  const filterJobs = () => {
    if (!searchQuery) return;

    setFiltering(true);
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = JOBS.filter((job) =>
      job.company.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredJobs(filtered);
  };

  const handleSearchClick = () => {
    filterJobs();
    setCurrentPage(1);
  };

  const totalJobs = filttering ? filteredJobs : JOBS;

  const totalPages = Math.ceil(totalJobs.length / JOBS_PER_PAGE);

  const handlePageChange = (_: any, newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <PageWrapper>
      <Box padding={isMobile ? "80px 20px" : "80px 108px"}>
        <Grid container>
          <Grid item xs={12}>
            <Stack alignItems={"center"}>
              <Typography variant="h4" fontWeight="bold" textAlign="center">
                Browse all of our job offers
              </Typography>
              <TextField
                name="search"
                label="Search by name (min. 3 letters)"
                sx={{
                  width: "320px",
                  marginTop: 4,
                  marginBottom: 2,
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Stack direction={"row"} spacing={"8px"}>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{
                    borderRadius: "20px",
                    textTransform: "none",
                  }}
                  onClick={handleSearchClick}
                >
                  Search
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{
                    borderRadius: "20px",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setSearchQuery("");
                    setFilteredJobs([]);
                    setFiltering(false);
                  }}
                  disabled={!filttering}
                >
                  Clear
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={8}>
          {filttering && totalJobs.length === 0 ? (
            <Grid item xs={12}>
              <Box
                height={"100%"}
                minHeight={"400px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography
                  variant="h6"
                  color="textSecondary"
                  textAlign={"center"}
                >
                  Job offers with company name containing "{searchQuery}" not
                  found
                </Typography>
              </Box>
            </Grid>
          ) : (
            totalJobs.slice(startIndex, endIndex).map((job) => (
              <Grid item xs={12} lg={6} key={job.id}>
                <Box
                  width={"100%"}
                  height={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <JobOfferCard data={job} />
                </Box>
              </Grid>
            ))
          )}
        </Grid>
        {totalPages >= 2 && (
          <Box mt={8} display="flex" justifyContent="center">
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              shape="rounded"
            />
          </Box>
        )}
      </Box>
    </PageWrapper>
  );
};

export default JobsView;
