import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PageWrapper } from "../../components/page-wrapper";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useYupValidationResolver } from "../../../utils/yup-resolver";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

interface ContactForm {
  name: string;
  email: string;
  details: string;
}

const contactForm = yup.object({
  name: yup.string().required("Name is required"),
  email: yup.string().required("Email is required").email("Email is invalid"),
  details: yup.string().required("Details are required"),
});

const ContactView = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const navigate = useNavigate();
  const resolver = useYupValidationResolver(contactForm);

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<ContactForm>({ resolver });

  const onSubmit = async (data: any) => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/contact`, {
      method: "POST",
      body: JSON.stringify({
        ...data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        toast.success(
          "Message sent successfully! \nWe will contact you as soon as possible!"
        );
        reset();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  return (
    <PageWrapper>
      <Box padding={isMobile ? "80px 20px" : "80px 108px"}>
        <Grid container>
          <Grid item xs={12}>
            <Stack
              alignItems={"center"}
              mt={8}
              component={"form"}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Typography fontWeight={"bold"} variant="h4" textAlign={"center"}>
                Contact us
              </Typography>
              <Typography variant="body1" textAlign={"center"} mt={2}>
                *You can also call us at +48 123 456 789
              </Typography>
              <TextField
                label="Name"
                sx={{
                  width: "320px",
                  marginTop: 4,
                }}
                {...register("name")}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
              <TextField
                label="Email"
                sx={{ width: "320px", marginTop: 2 }}
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
              <TextField
                label="Phone (optional)"
                sx={{ width: "320px", marginTop: 2 }}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
              <TextField
                label="Details"
                sx={{ width: "320px", marginTop: 2 }}
                multiline
                minRows={6}
                maxRows={8}
                {...register("details")}
                error={!!errors.details}
                helperText={errors.details?.message}
              />
              <Button
                sx={{
                  marginTop: 4,
                  borderRadius: "20px",
                }}
                variant={"contained"}
                size="large"
                disabled={!isDirty || loading}
                type="submit"
              >
                Send
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </PageWrapper>
  );
};

export default ContactView;
