import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { JobOfferCard } from "../../../../components/job-offer-card";
import { JOB_OFFERS } from "../../../../../config/offers";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../../config/routes";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const JOBS = JOB_OFFERS;

const JobsLookupSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();

  return (
    <Stack
      width={"100%"}
      padding={isMobile ? "40px 20px" : "0px 108px"}
      height={"100%"}
      minHeight={"100vh"}
    >
      <Typography variant="h3" fontWeight={"bold"} textAlign={"center"}>
        Get familiar with our job offers
      </Typography>
      <Grid container mt={8} spacing={4}>
        {JOBS.slice(0, 4).map((job) => (
          <Grid item xs={12} lg={6} key={job.id}>
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <JobOfferCard data={job} />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box
        mt={8}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        onClick={() => navigate(routes.jobs)}
        sx={{
          cursor: "pointer",
        }}
      >
        <Typography variant="h4" color={"primary"} mr={1}>
          View all offers
        </Typography>
        <ArrowForwardIcon
          color="primary"
          sx={{
            height: "32px",
            width: "32px",
          }}
        />
      </Box>
    </Stack>
  );
};

export { JobsLookupSection };
