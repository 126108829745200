import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PageWrapper } from "../../components/page-wrapper";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { routes } from "../../../config/routes";
import { JOB_OFFERS } from "../../../config/offers";
import { AddCircle, ArrowBack } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { useYupValidationResolver } from "../../../utils/yup-resolver";
import * as yup from "yup";
import toast from "react-hot-toast";

interface JobApplyForm {
  companyName: string;
  kvk: string;
  name: string;
  insurance: "yes" | "no";
  experience: "helper" | "2y" | "5y";
  tools: "yes" | "no";
  toolType: "hand" | "eletric" | "full";
  nativeLanguage: string;
  extraLanguage: string;
  email: string;
  phone: string;
}

const validationSchema = yup.object({
  companyName: yup.string().required("Company name is required"),
  email: yup.string().email().required("Email is required"),
  experience: yup.string().required("Experience is required"),
  insurance: yup.string().required("Insurance is required"),
  name: yup.string().required("Name is required"),
  phone: yup.string().required("Phone is required"),
  kvk: yup.string().required("KVK is required"),
  nativeLanguage: yup.string().required("Native language is required"),
  toolType: yup.string(),
  tools: yup.string(),
  additionalLanguages: yup.array().of(yup.string()),
});

const JobApplyView = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const { id } = useParams();
  const resolver = useYupValidationResolver(validationSchema);

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
    watch,
  } = useForm<JobApplyForm>({ resolver });

  const [additionalLanguageList, setAdditionalLanguageList] = useState<
    string[]
  >([]);

  const addAdditionalLanguage = (language: string) => {
    if (additionalLanguageList.includes(language)) {
      return;
    }
    setAdditionalLanguageList([...additionalLanguageList, language]);
  };

  const removeAdditionalLanguage = (language: string) => {
    setAdditionalLanguageList(
      additionalLanguageList.filter((lang) => lang !== language)
    );
  };

  useEffect(() => {
    if (!id) {
      navigate(routes.jobs);
    }
  }, [id]);

  const offerData = JOB_OFFERS.find((offer) => offer.id === id);
  const offerId = id as string;

  const values = watch();

  const onSubmit = async (data: any) => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/apply/${id}`, {
      method: "POST",
      body: JSON.stringify({
        ...data,
        additionalLanguages: additionalLanguageList,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        toast.success("Applied successfully!");
        navigate(routes.jobs);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  return (
    <PageWrapper>
      <Box padding={isMobile ? "80px 20px" : "80px 108px"}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              width={"100px"}
              onClick={() => navigate(routes.details.replace(":id", offerId))}
              display={"flex"}
              alignItems={"center"}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  opacity: 0.8,
                },
              }}
            >
              <ArrowBack />
              <Typography variant="h6">Go back</Typography>
            </Box>
            <Box component={"form"} onSubmit={handleSubmit(onSubmit)} mt={4}>
              <Typography fontWeight={"bold"} variant="h4">
                Apply for {offerData?.role}
              </Typography>
              <Typography mt={4} variant="h5">
                {offerData?.company}
              </Typography>
              <Typography mt={1} variant="h6">
                {offerData?.location}
              </Typography>
              <Stack mt={8} spacing={2}>
                <TextField
                  label="Name"
                  {...register("name")}
                  error={!!errors?.name}
                  helperText={errors.name?.message}
                  sx={{
                    maxWidth: "300px",
                  }}
                />
                <TextField
                  label="Email"
                  {...register("email")}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  sx={{
                    maxWidth: "300px",
                  }}
                />
                <TextField
                  label="Phone"
                  {...register("phone")}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                  sx={{
                    maxWidth: "300px",
                  }}
                />
                <TextField
                  label="Company Name"
                  {...register("companyName")}
                  error={!!errors.companyName}
                  helperText={errors.companyName?.message}
                  sx={{
                    maxWidth: "300px",
                  }}
                />
                <TextField
                  label="KvK"
                  {...register("kvk")}
                  error={!!errors.kvk}
                  helperText={errors.kvk?.message}
                  sx={{
                    maxWidth: "300px",
                  }}
                />
                <FormControl component="fieldset" error={!!errors.insurance}>
                  <FormLabel component="legend">Has insurance?</FormLabel>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    defaultValue="no"
                    name="insurance"
                    render={({ field }) => (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Experience?</FormLabel>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    defaultValue="helper"
                    name="experience"
                    render={({ field }) => (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value="helper"
                          control={<Radio />}
                          label="Helper"
                        />
                        <FormControlLabel
                          value="2y"
                          control={<Radio />}
                          label="2years+"
                        />
                        <FormControlLabel
                          value="5y"
                          control={<Radio />}
                          label="5years+"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Tools?</FormLabel>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    defaultValue="no"
                    name="tools"
                    render={({ field }) => (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
                {values.tools === "yes" && (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Tools?</FormLabel>
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      defaultValue="hand"
                      name="toolType"
                      render={({ field }) => (
                        <RadioGroup row {...field}>
                          <FormControlLabel
                            value="hand"
                            control={<Radio />}
                            label="Hand"
                          />
                          <FormControlLabel
                            value="eletric"
                            control={<Radio />}
                            label="Electric"
                          />
                          <FormControlLabel
                            value="full"
                            control={<Radio />}
                            label="Full"
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                )}

                <TextField
                  label="Native Language"
                  {...register("nativeLanguage")}
                  error={!!errors.nativeLanguage}
                  helperText={errors.nativeLanguage?.message}
                  sx={{
                    maxWidth: "300px",
                  }}
                />

                <Stack direction={"row"} spacing={2}>
                  <TextField
                    label="Additional Language"
                    {...register("extraLanguage")}
                  />
                  <IconButton
                    color="primary"
                    disabled={
                      !values.extraLanguage ||
                      values.extraLanguage === values.nativeLanguage
                    }
                    onClick={() => addAdditionalLanguage(values.extraLanguage)}
                  >
                    <AddCircle
                      sx={{
                        width: "32px",
                        height: "32px",
                      }}
                    />
                  </IconButton>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={"4px"}
                  flexWrap={"wrap"}
                  maxWidth={"300px"}
                >
                  {additionalLanguageList.map((language) => (
                    <Chip
                      variant="outlined"
                      onDelete={() => removeAdditionalLanguage(language)}
                      label={language}
                    />
                  ))}
                </Stack>
              </Stack>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  borderRadius: "20px",
                  marginTop: 4,
                }}
                disabled={!isDirty || loading}
              >
                Apply
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </PageWrapper>
  );
};

export default JobApplyView;
