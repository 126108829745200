import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../../config/routes";

const ContactSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();

  return (
    <Stack
      padding={isMobile ? "40px 20px" : "0px 108px"}
      minHeight={"100vh"}
      height={"100%"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            justifyContent={"center"}
          >
            <img
              src="/contact.jpg"
              alt="contact"
              style={{
                height: "100%",
                maxWidth: "100%",
                borderRadius: "40px",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Stack
            width={"100%"}
            height={"100%"}
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            mt={isMobile ? 2 : 0}
          >
            <Box maxWidth={"600px"} width={"100%"}>
              <Typography variant="h3" fontWeight={"bold"}>
                Any questions?
              </Typography>
              <Typography variant="h4" fontWeight={"bold"} mt={2}>
                we are here to help you!
              </Typography>
              <Typography variant="h6" mt={2} mb={4}>
                Dont hesitate to contact us, we are here to help you! We will
                provide you any assistance you need.
              </Typography>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  borderRadius: "20px",
                  textTransform: "none",
                }}
                onClick={() => navigate(routes.contact)}
              >
                Contact Us
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export { ContactSection };
