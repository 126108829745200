import { Typography } from "@mui/material";
import { PageWrapper } from "../../components/page-wrapper";

const NotFoundView = () => {
  return (
    <PageWrapper>
      <Typography variant="h3">Page not found</Typography>
    </PageWrapper>
  );
};

export default NotFoundView;
