import { PageWrapper } from "../../components/page-wrapper";
import { ContactSection } from "./components/contact";
import { HeroSection } from "./components/hero";
import { JobsLookupSection } from "./components/jobs-lookup";

const HomeView = () => {
  return (
    <PageWrapper>
      <HeroSection />
      <JobsLookupSection />
      <ContactSection />
    </PageWrapper>
  );
};

export default HomeView;
